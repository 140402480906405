<template>
  <!-- Footer -->
  <footer class="-rn-footer" id="footer">

    <div class="rn-footer-wrapper footer">
      <div class="footer-wrapper text-center">
        <v-container fluid>
                             
          <h3 class="font-weight-light pb-3">
            Carolyn Squire
            <small class="pl-2 grey--text text--darken-1">Dip Arch RIBA</small>
          </h3>
          
          <v-row>

            <v-col md="3" cols="12">
              <div class="footer-widget">
                <div class="content">
                    <img
                      src="@/assets/carolyn/cs-photo.jpg"
                      alt="Carolyn Squire - photo"
                      style="max-width:140px"
                    />
                </div>
              </div>
            </v-col>
            <v-col md="3" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">Address</h3>
                <div class="content">
                  <p>
                    122 Church Walk <br/>
                    Stoke Newington<br />
                    London N16 8QW
                  </p>
                </div>
              </div>
            </v-col>
            <v-col md="3" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">Telephone</h3>
                <div class="content">
                  <p>
                    <span class="d-block mb-2"><v-icon>mdi-phone</v-icon> 020 7254 0624</span>
                    <span><v-icon>mdi-cellphone</v-icon> 07976 374 221</span>
                  </p>
                </div>
              </div>
            </v-col>
            <v-col md="3" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">Email</h3>
                <div class="content">
                  <div class="address">
                    <p>
                      <a href="mailto:example@domain.com">mail@carolynsquire.co.uk</a>
                    </p>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

  </footer>
  <!--// Footer -->
</template>

<script>
  export default {
    data() {
      return {
        quickLink: [
          {
            name: "Blog",
            RouteName: "/blog",
          },
          {
            name: "Service",
            RouteName: "service",
          },
          {
            name: "Portfolio",
            RouteName: "portfolio",
          },
        ],
      };
    },
  };
</script>

<style lang="scss" scoped></style>
