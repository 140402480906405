<template>
  <div>
    <HeaderMain />

    <div class="box-layout">

      <v-container fluid>

        <h2 class="py-12 text-center font-weight-light">{{category.title}}</h2>

        <v-row class="row--55" id="category-cards">
          <v-col
            v-for="project in projects"
            :key="project.id"
            :cols="12"
            :md="4"
          >
            <router-link :to="{ name: 'project', params: { category: category.slug, project: project.slug } }">
              <v-card
                outlined
                class="mb-10"
              >
                <v-img
                  :src="project.imgSrc"
                  class="white--text align-end"
                  height="500px"
                >
                  <v-card-title v-text="project.title"></v-card-title>
                </v-img>
              </v-card>
            </router-link>
          </v-col>
        </v-row>

      </v-container>

      <Footer />

    </div>
  </div>

</template>

<script>
  import HeaderMain from "@/components/Header";
  import Footer from "@/components/Footer";
  import { getProjects } from "../services";
  export default {
    components: {
      HeaderMain,
      Footer
    },
    data() {
      return {
        category: {},
        projects: [],
        blurb: ''
      };
    },
    created() {
      const { category } = this.$route.params;
       this.fetchData(category);
    },
    beforeRouteUpdate (to, from, next) {
      this.fetchData(to.params.category);
      next();
    },
    methods: {
      fetchData(_category) {
        this.category = getProjects().find(item => item.slug === _category);
        this.projects = this.category.projects.map(item => {
          return {
            title: item.title,
            location: item.location,
            slug: item.slug,
            blurb: item.text,
            imgSrc: require(`@/assets/carolyn/${this.category.slug}/${item.slug}/${item.photos[0]}`)
          }
        })
        document.title = document.title + ` | ${this.category.title}`;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .box-layout {
    .-rn-footer {
      background: #f9f9f9;
    }
    header.header.header-flat {
      padding: 0;
    }
    #category-cards {
      .v-card {
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.25);
        transition: box-shadow 500ms;
        &:hover {
          box-shadow: 0 3px 30px rgb(0 0 0 / 0.5);
        }
      }
      .v-card__title {
        word-break: break-word;
        background-color: rgb(0 0 0 / 0.5);
      }
    }
  }
</style>