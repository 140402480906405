<template>
  <div class="portfolio-details-page">

    <HeaderMain />

    <div class="box-layout">

      <!-- Start Portfolio Details Area -->
      <div class="rf-portfolio-details mt-10 pt-md-10">
        <v-container class="container">
          <v-row>

            <v-col sm="6" cols="12" class="pr-md-15">
              <div class="rp-port-details-inner sticky-top pt-0">
                <div class="content" id="project-details">

                  <h3>{{project.title}}</h3>
                  <h4>{{project.location}}</h4>

                  <p class="pt-5">
                    <span
                      v-for="(line,lineNumber) in project.text.split('\n')" 
                      v-bind:key="lineNumber">
                      {{ line }}<br/><br/>
                    </span>
                  </p>

                </div>
              </div>
            </v-col>

            <v-col sm="6" cols="12">
              
              <div class="rp-port-thumb">
                
                <div
                  v-for="photo in photos"
                  :key="photo.id"
                  class="port-single-thumb mt-6"
                >
                  <v-img
                    :src="photo.imgSrc"
                  />
                </div>

              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Portfolio Details Area -->

      <Footer />

    </div>

    </div>

</template>

<script>
  import HeaderMain from "@/components/Header";
  import Footer from "@/components/Footer";
  import { getProjects } from "../services";
  export default {
    components: {
      HeaderMain,
      Footer
    },
    data() {
      return {
        category: {},
        project: {
          text: ''
        },
        photos: []
      };
    },
    mounted() {
       this.fetchData();
    },
    methods: {
      fetchData() {
        const { category, project } = this.$route.params;
        this.category = getProjects().find(item => item.slug === category);
        this.project = this.category.projects.find(item => item.slug === project);
        this.photos = this.project.photos.map((item, index) => {
          return {
            id: index,
            imgSrc: require(`@/assets/carolyn/${this.category.slug}/${this.project.slug}/${item}`)
          }
        })

        document.title = document.title + ` | ${this.category.title} | ${this.project.title}`;
      },
    },
  };
</script>

<style lang="scss" scoped>
#project-details {
  h3 {
      font-weight: 300;
      margin-bottom: 15px;
  }
  h4 {
      font-weight: 300;
      color: #666;
      text-transform: uppercase;      
  }
}
</style>