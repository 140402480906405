import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import Missing from '@/views/404.vue'
import Home from '@/views/Home.vue'
import Category from '@/views/Category.vue'
import Project from '@/views/Project.vue'
import Consulttion from '@/views/Consultation.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Home,
        meta: {
            title: 'Carolyn Squire | Architect'
        }
    },
    {
        path: '/category/:category',
        name: 'category',
        component: Category,
        meta: {
            title: 'Carolyn Squire | Architect'
        }
    },
    {
        path: '/projects/:category/:project',
        name: 'project',
        component: Project,
        meta: {
            title: 'Carolyn Squire | Architect'
        }
    },
    {
        path: '/consultation',
        component: Consulttion,
        meta: {
            title: 'Carolyn Squire | Architect | Consultation'
        }
    },
    {
        path: '*',
        component: Missing,
        meta: {
            title: 'Rainfo || 404'
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})


export default router