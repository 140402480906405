const getProjects = () => {
    return [
        {
            id: 1,
            title: "New Build Houses", 
            slug: "new-build-houses",
            photo: "millfields_rd.jpg",
            projects: [
                {
                    id: 1,
                    title: "Church Walk", 
                    slug: "church-walk-1",
                    location: "Stoke Newington",
                    text: 
                    `A pre-application submission for a new dwelling in the rear of a garden which fronted Church Walk received a favourable response and now planning approval has been granted for a 1 bedroom house.
                    The fully glazed elevation to the street takes advantage of the light from the West with the ground floor being set back to give privacy and security. The rear which faces the house behind has a pitched glazed roof so that the views from the house will not be impeded.
                    The walls will be clad in fibrous cement, a robust finish, which will integrate the new building into the streetscape.`,
                    mainPhoto: "house-church-walk_b1.jpg",
                    photos: [
                        'house-church-walk_b1.jpg',
                        'house-church-walk_b2.jpg',
                    ]        
                },
                {
                    id: 2,
                    title: "Milton Grove", 
                    slug: "milton-grove-1",
                    location: "N16",
                    text: 
                    `An existing garage was demolished and a new 2 storey house has been built in the rear garden of the house in Allen Road. The house maintains a brick boundary wall to the street with the first floor being set back from this to reduce any feeling of intrusion onto the street.
                    The living areas have been lowered from the street level and open into a south facing courtyard space. The dark grey bricks have been chosen to relate to the darkened London stock bricks of the surrounding houses.`,
                    photos: [
                        'house-milton-grove_b2.jpg',
                        'L1060767.jpg',
                        'L1060693.jpg',
                        'L1060688.jpg',
                        'L1060683-d.jpg',
                        'L1060680.jpg',
                        'L1060666.jpg',
                    ]        
                },
                {
                    id: 3,
                    title: "Evering Road", 
                    slug: "evering-road",
                    location: "Stoke Newington",
                    text: 
                    `A difficult site with derelict lock up garages was given planning permission for change of use to two houses. These formed a modern completion to the road, stepping down in scale towards the rear gardens to the east.`,
                    photos: [
                        'evering_rd_b1.jpg',
                    ]        
                },
                {
                    id: 4,
                    title: "Millfields Road", 
                    slug: "millfields-road",
                    location: "E5",
                    text: 
                    `The site was restricted in size and aspect, window positions were restricted by overlooking restraints. To overcome this the living areas were on the upper floors with a double height top lit space. A slim bay window reflects the adjoining Victorian houses and allows discrete views along the street.`,
                    photos: [
                        'millfields_rd_b1.jpg',
                        'millfields_rd_b2.jpg',
                        'millfields_rd_b4.jpg',
                        'millfields_rd_b5.jpg',
                        'millfields_rd_b6.jpg',
                        'millfields_rd_b7.jpg',
                        'millfields_rd_b9.jpg',
                        'millfields_rd_b10.jpg',
                        'millfields_rd_b11.jpg',
                    ]        
                },
                {
                    id: 5,
                    title: "Brooke Road", 
                    slug: "brooke-road",
                    location: "E5",
                    text: 
                    `The land had Planning Permission for a 3 bedroom bungalow. External additions such as the bedroom balcony and the opening up of the ground floor living area changed it into a modern house..`,
                    photos: [
                        'brook_rd_b1.jpg',
                        'brook_rd_b2.jpg',
                        'brook_rd_b3.jpg',
                        'brook_rd_b4.jpg',
                    ]        
                },
                {
                    id: 6,
                    title: "Studio", 
                    slug: "studio",
                    location: "Stoke Newington",
                    text: 
                    `Four garages were converted into a studio with small kitchen and shower for use for an architect The original garage door openings were filled with glass blocks for security and to bring in west facing light. The rooflights and east facing windows ensure that there is a good working environment throughout the day and simple finishes and underfloor heating make this a very flexible space.`,
                    photos: [
                        'church_walk_b1.jpg',
                        'church_walk_b2.jpg',
                    ]        
                },
                {
                    id: 7,
                    title: "Milton Grove", 
                    slug: "milton-grove-2",
                    location: "N16",
                    text: 
                    `The land was bought with Planning Permission by a development company. Changes to the internal layout to make it a more practical space resulted in a swift purchase.`,
                    photos: [
                        'milton_grove_b1.jpg',
                    ]        
                },
                {
                    id: 8,
                    title: "Malford Grove", 
                    slug: "malford-grove",
                    location: "E18",
                    text: 
                    `The client owned a small bungalow with a large garden which was badly insulated and too small for his family. Planning permission was granted for a new 2 storey house following the same footprint. The house  was designed for a multi -generational family and includes a lift for future use.`,
                    photos: [
                        'L1060510.jpg',
                        '20191022_122137.jpg',
                        '20191022_122025.jpg',
                    ]        
                },
                {
                    id: 9,
                    title: "Church Walk", 
                    slug: "church-walk-2",
                    location: "Stoke Newington",
                    text: 
                    `Planning Approval for a 1 bedroom house to replace the existing garage is currently being implemented. The house has the same footprint and form Church Walk [1]but the materials and design reflect the different aesthetic preferences of the client. The pre- application advice made specific mention for this varied approach to development in this street. The brickwork and steel doors will give a simple robust appearance which will reflects other commercial buildings in the street.`,
                    photos: [
                        'view1.jpg',
                        'view2.jpg',
                    ]        
                },
            ]
        },
        {
            id: 1,
            title: "Refurbs and Extensions", 
            slug: "refurbs-and-extensions",
            photo: "extension-debauvoir.jpg",
            projects: [
                {
                    id: 1,
                    title: "Side Extension", 
                    slug: "side-extension-allen",
                    location: "Allen",
                    text: 
                    `The kitchen was in the extension and felt very separate from the living area- a lovely space with high ceilings from its previous use as a shop. Extending to the side of the kitchen enabled both spaces to be linked but still function separately. 
                    The existing two storey party wall enabled the new ceiling height to be the same as in the main house.`,
                    mainPhoto: "4_b.jpg",
                    photos: [
                        '4_b.jpg',
                        '1_b.jpg',
                        '2_b.jpg',
                        '3_b.jpg',
                        '5_b.jpg',
                        '6_b.jpg',
                    ]        
                },
                {
                    id: 2,
                    title: "Roof Extension", 
                    slug: "roof-extension-forburg",
                    location: "Forburg",
                    text: 
                    `The roof had been used for storage and the height was sufficient for a bedroom without having the need for a dormer. This would have been restricted in size in any case as the house is in a Conservation Area and the clients were anxious not to lose the interesting shape of the roof. 
                    The Veluxes were aligned one above the other so that views of the garden and trees were not lost and the front bay roof was opened up to give another little space with light from another direction.`,
                    mainPhoto: "2_b.jpg",
                    photos: [
                        '2_b.jpg',
                        '1_b.jpg',
                        '3_b.jpg',
                    ]        
                },
                {
                    id: 3,
                    title: "Rear Extension", 
                    slug: "rear-extension-allerton",
                    location: "Allerton",
                    text: 
                    `The rear extension was designed as a children's playroom - the room can work on its own or be opened to form a larger playing area. The loft was opened up to maximise the views over the Reservoir and is an adult retreat.`,
                    mainPhoto: "allerton_b1.jpg",
                    photos: [
                        'allerton_b1.jpg',
                        'allerton_b2.jpg',
                        'allerton_b3.jpg',
                        'allerton_b4.jpg',
                        'allerton_b5.jpg',
                    ]        
                },
                {
                    id: 4,
                    title: "House Modernisation", 
                    slug: "house-modernisation-park-view",
                    location: "Park View",
                    text: 
                    `A small house, which was of sound construction but had been very poorly maintained, was stripped back and restored with the bathroom at the rear being moved upstairs and being replaced with the living space with direct access to the garden. 
                    The island in the kitchen is for preparation but also forms the dining table so that the house still has three bedrooms. The kitchen has been designed so that when a loft is built later then the kitchen can be opened up to the front room.`,
                    mainPhoto: "1_b.jpg",
                    photos: [
                        '1_b.jpg',
                        '2_b.jpg',
                        '3_b.jpg',
                        '4_b.jpg',
                    ]        
                },
                {
                    id: 5,
                    title: "House Modernisation", 
                    slug: "house-modernisation-islington",
                    location: "Islington",
                    text: 
                    `The house, which remained as original with no modernisation, was transformed into a new family home with extensions in the lower ground floor and the roof.`,
                    mainPhoto: "islington_house_b1.jpg",
                    photos: [
                        'islington_house_b1.jpg',
                        'islington_house_b2.jpg',
                    ]        
                },
                {
                    id: 6,
                    title: "Side Extension", 
                    slug: "side-extension-islington",
                    location: "Islington",
                    text: 
                    `The existing narrow kitchen was extended to the side to form an enlarged space linked to the garden.`,
                    mainPhoto: "islington_side_ext_b1.jpg",
                    photos: [
                        'islington_side_ext_b1.jpg',
                        'islington_side_ext_b2.jpg',
                    ]        
                },
                {
                    id: 7,
                    title: "Roof Extension", 
                    slug: "roof-extension-islington",
                    location: "Islington",
                    text: 
                    `A leaking exposed terrace was changed to a flexible garden room for use in all seasons.`,
                    mainPhoto: "islington_roof_ext_b1.jpg",
                    photos: [
                        'islington_roof_ext_b1.jpg',
                        'islington_roof_ext_b2.jpg',
                    ]        
                },
                {
                    id: 8,
                    title: "Side and Rear Extension", 
                    slug: "side-and-rear-extension-debeauvoir",
                    location: "De Beauvoir",
                    text: 
                    `The side garden formed the new access to an enlarged kitchen/dining space which despite being north facing feels light and airy. The practice worked closely with the client to position the rooflights exactly in order to maximize sunlight and the rear opens completely into the garden.`,
                    mainPhoto: "ufton_b1.jpg",
                    photos: [
                        'ufton_b1.jpg',
                        'ufton_b2.jpg',
                        'ufton_b3.jpg',
                        'ufton_b4.jpg',
                        'ufton_b5.jpg',
                    ]        
                },
                {
                    id: 9,
                    title: "Side and Roof Extension", 
                    slug: "side-and-roof-extension-stoke-newington",
                    location: "Stoke Newington",
                    text: 
                    `The house was in a very dilapidated state and was completely modernized and extended to form a light and friendly family home.`,
                    mainPhoto: "nevill_b1.jpg",
                    photos: [
                        'nevill_b1.jpg',
                        'nevill_b2.jpg',
                        'nevill_b3.jpg',
                        'nevill_b4.jpg',
                    ]        
                },
                {
                    id: 10,
                    title: "Rear Extension", 
                    slug: "rear-extension-willesden",
                    location: "Willesden",
                    text: 
                    `The semi detached house, built in the 30’s, had small dark rooms at the rear of the ground floor. The new utility and shower room were positioned in the middle of the house so that the kitchen /dining could open up to the garden. Materials were similar but slightly changed to reflect the new and the old.`,
                    mainPhoto: "L1060592.jpg",
                    photos: [
                        'L1060592.jpg',
                        'L1060596.jpg',
                        'L1060600.jpg',
                    ]        
                },
                {
                    id: 11,
                    title: "Rear Extension", 
                    slug: "rear-extension-muswell-hill",
                    location: "Muswell Hill",
                    text: 
                    `An old WC and kitchen extension which blocked the late afternoon sun were demolished in order to erect  a  new kitchen /dining room with  better light. As the kitchen is north facing a fully glazed roof works well and the lightweight structure does not intrude on the leafy back gardens.`,
                    mainPhoto: "barnard_3.jpg",
                    photos: [
                        'barnard_3.jpg',
                        'barnard_2.jpg',
                        'barnard_6.jpg',
                    ]        
                },
                {
                    id: 12,
                    title: "Rear Extension", 
                    slug: "rear-extension-leyton",
                    location: "Leyton",
                    text: 
                    `A dilapidated veranda was replaced with a double glazed small extension on the same footprint. Steel windows and doors fitted well with the partial modernizing which had happened in the house between the wars.`,
                    mainPhoto: "L1060785.jpg",
                    photos: [
                        'L1060785.jpg',
                        'L1060782.jpg',
                        'L1060783.jpg',
                        'L1060784.jpg',
                        'L1060786.jpg',
                        'L1060787.jpg',
                    ]        
                },
                {
                    id: 13,
                    title: "Flat Refurbishment", 
                    slug: "flat-refurbishment–kentish-town",
                    location: "Kentish Town",
                    text: 
                    `The Polytechnic had been converted to residential use very successfully about 30 years ago. The clients bought two flats already partially converted into one unit and the project was to modernize the flat and make the interior function better. 
                    The kitchen, originally on the lower floor, was moved to the top floor where  the dining space looked onto a small south facing terrace. The lower floor with its double height space   became the library and music room. All the original floors were sanded so that they were lighter  and more contemporary.`,
                    mainPhoto: "4k.jpg",
                    photos: [
                        '4k.jpg',
                        '5k.jpg',
                        '9k.jpg',
                        '12k.jpg',
                    ]        
                },
                {
                    id: 14,
                    title: "Rear Extension", 
                    slug: "rear-extension-debeauvoir",
                    location: "De Beauvoir",
                    text: 
                    `The flat is in a modern block in a Conservation Area. The client wished to keep the external wall of the flat in fair faced brick when it became part of the new kitchen and social area. 
                    The new part was then linked with the old and the original bricks were sourced for the new external walls. The new doors were in aluminium which will not only be better for maintenance but also easier to open up to the garden.`,
                    mainPhoto: "hertford_b1.jpg",
                    photos: [
                        'hertford_b1.jpg',
                        'hertford_b2.jpg',
                        'hertford_b3.jpg',
                        'hertford_b4.jpg',
                    ]        
                },
                {
                    id: 15,
                    title: "Roof Extension", 
                    slug: "roof-extension-stoke-newington",
                    location: "Stoke Newington",
                    text: 
                    `The client wanted an upper living area to be a modern extension to a house rebuilt after the war. The front wall of the room opens onto a terrace with great views and the modern finishes of beech flooring and ceramic tiles contrast well with the old stock bricks and leadwork to the fascia.`,
                    mainPhoto: "winston_rd_b1.jpg",
                    photos: [
                        'winston_rd_b1.jpg',
                    ]        
                },
                {
                    id: 16,
                    title: "Rear Extension", 
                    slug: "rear-extension-de-beauvoir",
                    location: "De Beauvoir",
                    text: 
                    `Warped doors to the old conservatory prevented the clients accessing their lovely garden. A modern structure replaced this and became a new kitchen/ dining/ potting shed.`,
                    mainPhoto: "mortimer_b1.jpg",
                    photos: [
                        'mortimer_b1.jpg',
                        'mortimer_b2.jpg',
                        'mortimer_b3.jpg',
                    ]        
                },
                {
                    id: 17,
                    title: "House Refurbishment ", 
                    slug: "house-refurbishment-islington",
                    location: "Islington",
                    text: 
                    `The house is triangular in plan because of its position at the end of an early Victorian terrace. The small but very green rear garden becomes the focal point on the lower levels where openings have been enlarged as much as possible.
                    The new kitchen and bathrooms are modern, the interior fittings such as bookcases have been chosen to sit comfortably with these but also relate to the age and original character of the house. The aim was to make the house warmer and brighter so it is a more relaxing home.`,
                    mainPhoto: "islington_b1.jpg",
                    photos: [
                        'islington_b1.jpg',
                        'islington_b2.jpg',
                        'islington_b3.jpg',
                        'islington_b4.jpg',
                        'islington_b5.jpg',
                    ]        
                },
                {
                    id: 18,
                    title: "Rear Extension", 
                    slug: "rear-extension-tower-hamlets",
                    location: "Tower Hamlets",
                    text: 
                    `The existing kitchen and playroom had no windows onto the garden. The kitchen remains in the same position but has been made sleek and functional – this leads to a new dining/ family room with light coming differing directions and doors leading straight out to the garden. The client’s choice of white ceramic tiles is a perfect transition from the darker kitchen into the new open area.`,
                    mainPhoto: "lyal_b2.jpg",
                    photos: [
                        'lyal_b2.jpg',
                        'lyal_b3.jpg',
                    ]        
                },
                {
                    id: 19,
                    title: "Rear Extension", 
                    slug: "rear-extension-stoke-newington",
                    location: "Stoke Newington",
                    text: 
                    `The dark under used external side space has been brought into the kitchen. The existing high quality kitchen has remained on one side but been reformed into an island unit in the middle of the new room.`,
                    mainPhoto: "palatine_b1.jpg",
                    photos: [
                        'palatine_b1.jpg',
                        'palatine_b2.jpg',
                        'palatine_b3.jpg',
                    ]        
                },
                {
                    id: 20,
                    title: "Rear Extension", 
                    slug: "rear-extension-crouch-end",
                    location: "Crouch End",
                    text: 
                    `An existing conservatory was underused because of heat loss and the kitchen was dark and not connected to the dining area.
                    The kitchen is now in the new rear extension with light coming from above from two directions. It is now directly linked to the original rear dining room. New timber doors to both new and existing spaces tie them together.`,
                    mainPhoto: "park-avenue-south_b1.jpg",
                    photos: [
                        'park-avenue-south_b1.jpg',
                        'park-avenue-south_b2.jpg',
                    ]        
                },

            ]
        },
        {
            id: 1,
            title: "Kitchens and Bathrooms", 
            slug: "kitchens-and-bathrooms",
            photo: "kitchen-debeavoir.jpg",
            projects: [
                {
                    id: 1,
                    title: "Kitchen", 
                    slug: "kitchen-westminster",
                    location: "Westminster",
                    text: 
                    `The kitchen in this Listed house was in the lower ground floor with no dining space at that level - a dumb waiter connected it with the dining room on the ground floor - a traditional formal arrangement for cooking and eating. 
                    A solution which led to a more informal socialising space was to move the kitchen into the rear of the ground floor and combine this with the dining area. A dark porcelain tile which started at the entrance was laid throughout this floor to give a continuous welcoming feel straight from the street.
                    The kitchen was built and detailed by a craftsman - the details were simple but felt appropriate for the house.`,
                    mainPhoto: "westminster_b1.jpg",
                    photos: [
                        'westminster_b1.jpg',
                        'westminster_b2.jpg',
                    ]        
                },
                {
                    id: 2,
                    title: "bathroom", 
                    slug: "bathroom-dalston",
                    location: "Dalston",
                    text: 
                    `Conservation Area restraints led to a very compact roof extension but there was still room for a shower.`,
                    mainPhoto: "dalston_b_b1.jpg",
                    photos: [
                        'dalston_b_b1.jpg',
                    ]        
                },
                {
                    id: 3,
                    title: "bathroom", 
                    slug: "bathroom-stoke-newington",
                    location: "Stoke Newington",
                    text: 
                    `The rear room in the main house was changed to a bathroom when the loft was extended. The client wanted a bathroom which kept the elegant feeling of the original Victorian room. Photos courtesy of Caroline Penn.`,
                    mainPhoto: "norcott_b1.jpg",
                    photos: [
                        'norcott_b1.jpg',
                        'norcott_b2.jpg',
                        'norcott_b3.jpg',
                        'norcott_b4.jpg',
                    ]        
                },
                {
                    id: 4,
                    title: "Kitchen", 
                    slug: "kitchen-angel",
                    location: "Angel",
                    text: 
                    `The original kitchen had an island which was too narrow to use properly so the kitchen was widened by redesigning the WC and utility areas and tucking them behind sliding doors. The cream tiles, white floor and reflective worktop brighten the basement room. Glass pendants give an attractive light and do not intrude in the room.`,
                    mainPhoto: "quick_b1.jpg",
                    photos: [
                        'quick_b1.jpg',
                        'quick_b2.jpg',
                    ]        
                },
                {
                    id: 5,
                    title: "Kitchen", 
                    slug: "kitchen-stoke-newington",
                    location: "Stoke Newington",
                    text: 
                    `The kitchen in this house was on the top floor with no access to the garden. A side extension at garden level made a kitchen/ dining space possible so a good family space has been created with interesting lighting.`,
                    mainPhoto: "listria_b1.jpg",
                    photos: [
                        'listria_b1.jpg',
                        'listria_b2.jpg',
                    ]        
                },
                {
                    id: 6,
                    title: "Kitchen", 
                    slug: "kitchen-kentish-town",
                    location: "Kentish Town",
                    text: 
                    `The lower ground floor flat was completely modernised with a new kitchen forming a focal part of the design. The client enjoys cooking so the kitchen had to look solid and timeless.`,
                    mainPhoto: "leighton_b1.jpg",
                    photos: [
                        'leighton_b1.jpg',
                        'leighton_b2.jpg',
                        'leighton_b3.jpg',
                    ]        
                },
                {
                    id: 7,
                    title: "Bathroom", 
                    slug: "bathroom-west-hampstead",
                    location: "West Hampstead",
                    text: 
                    `The client had bought a upper maisonette which needed upgrading - replacing the leaking shower was a very important part of the project. A new rooflight to the bathroom gave good views, fresh air and light and a level access shower tray ensured that it also functioned better.`,
                    mainPhoto: "L1060497.jpg",
                    photos: [
                        'L1060497.jpg',
                        'L1060499.jpg',
                    ]        
                },
                {
                    id: 8,
                    title: "Shower", 
                    slug: "shower-stoke-newington",
                    location: "Stoke Newington",
                    text: 
                    `The clients had enjoyed living in the large double fronted house for some time but the bathroom being two floors below the bedroom floor was not ideal. The space at the top of the stair was reconfigured so that the dual aspect of the house was more evident and a new shower room and storage area could be created.`,
                    mainPhoto: "L1060606.jpg",
                    photos: [
                        'L1060606.jpg',
                        'L1060608.jpg',
                    ]        
                },

            ]
        },
      ]
  }

  export {
      getProjects
  }
  