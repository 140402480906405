<template>
  <div>
    <HeaderMain />

    <div class="box-layout">

      <v-container fluid>

        <div class="rf-service-details py-12">
          <v-container class="container">
            <v-row>
              <v-col md="6" cols="12" order="2" order-lg="1">
                <div class="rp-port-details-inner">
                  <div class="content" id="consultation">

                      <h3 class="font-weight-light mb-0">Consultation</h3>

                      <p class="pt-5">
                        The practice has a policy of trying to meet the client brief as closely as possible – with that end in mind a consultation, usually at the clients property, can help to explore:
                      </p>

                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>spatial requirements</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>budget constraints</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>necessary approvals</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>programme</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>other consultants and contractors</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>budget constraints</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      
                  </div>
                </div>
              </v-col>
              <v-col md="6" cols="12" order-lg="2" class="pr--60 pr-md-15 pr-sm-15">
                <div class="rp-port-thumb">
                  <div class="port-single-thumb">
                    <img
                      src="@/assets/carolyn/studio.jpg"
                      alt="Carolyn's Studio"
                    />
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>

      </v-container>

      <Footer />

    </div>
  </div>

</template>

<script>
  import HeaderMain from "@/components/Header";
  import Footer from "@/components/Footer";
  export default {
    components: {
      HeaderMain,
      Footer
    },
    mounted() {
       document.title = document.title + ' | Consultation';
    },
    methods: {},
  };
</script>

<style lang="scss" scoped>
  .box-layout {
    .-rn-footer {
      background: #f9f9f9;
    }
    header.header.header-flat {
      padding: 0;
    }
  }
</style>