<template>
  <div id="header-main">
    <v-navigation-drawer
      class="hidden-md-and-up custom-navigation-drawer"
      v-model="drawer"
      fixed
      dark
      right
      width="320"
    >
      <scrollactive
        active-class="v-btn--active"
        bezier-easing-value=".5,0,.35,1"
        :offset="70"
        :duration="1000"
        dark
      >
        <v-list flat>
          <v-list-item
            :ripple="false"
            :to="'/'"
            link
            class="scrollactive-item"
          >
            <v-list-item-content>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :ripple="false"
            v-for="item in items"
            :key="item.title"
            :to="item.to"
            link
            class="scrollactive-item"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- End mobile menu sidebar item list -->
        </v-list>
      </scrollactive>
      <div class="menu menu-toggle open" id="menu-toggle">
        <div class="menu-toggle-inner" @click="drawer = !drawer">
          <div class="menu-text-wrap">
            <span class="menu-text close-text">Close</span>
          </div>
          <div class="hamburger-wrap">
            <div id="hamburger">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->

    <v-app-bar class="header header-flat" height="116" flat dark>
      <div>
        <router-link to="/" class="logo">
          <img src="../assets/carolyn/logos/logo_black_bg.png" >
        </router-link>
      </div>
      <!-- End brand logo -->
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <!-- End mobile menu icon -->
      <v-toolbar-items
        class="hidden-xs-only hidden-sm-only height-auto mainmenu-wrap"
      >
        <v-btn
          :to="'/'"
          link
          :ripple="false"
          text
          ><v-icon>mdi-home</v-icon></v-btn
        >
        <v-btn
          v-for="item in items"
          :key="item.title"
          :to="item.to"
          link
          :ripple="false"
          text
          class="text-lowercase"
          >{{ item.title }}</v-btn
        >
      </v-toolbar-items>
      <!-- End header menu item -->
    </v-app-bar>
    <!-- End top header navbar -->
  </div>
</template>

<script>
  export default {
    data: () => ({
      drawer: false,
      items: [
        { title: "New Build Houses", to: "/category/new-build-houses" },
        { title: "Refurbs and Extensions", to: "/category/refurbs-and-extensions" },
        { title: "Kitchens and Bathrooms", to: "/category/kitchens-and-bathrooms" },
        { title: "Consultation", to: "/consultation" },
      ],
      icon: "menu",
    }),
    methods: {
      goTo(_category) {
        this.$router.push({ name: 'category', params: { category: _category } })
      }
    },
  };
</script>
<style lang="scss">
    #header-main header.header {
      background-color: #000000;
      .logo {
        max-width: 400px;
      }
    }  
</style>

