<template>
  <div>
    <HeaderMain />

    <div class="box-layout">

      <v-container fluid>

        <v-row class="py-12">
          <v-col md="6" offset-md="3" class="text-justify font-weight-light">
              <p>
                Carolyn Squire has been running a residential practice in Stoke Newington for over 35 years, providing clients with a highly personalised service on a range of projects from kitchens and bathrooms to conversions, extensions and new build houses.
              </p>
              <p>
                The practice aims to be able to create space that is functional, uplifting and a long-term asset both to the client and the wider built environment.
                </p>
          </v-col>
        </v-row>

        <v-row class="row--55" id="home-cards">
          <v-col
            v-for="card in categories"
            :key="card.title"
            :cols="12"
            :md="4"
          >
            <router-link :to="{ name: 'category', params: { category: card.slug } }">
              <v-card
                outlined
                class="mb-10"
              >
                <v-img
                  :src="card.imgSrc"
                  class="white--text align-end"
                  height="500px"
                >
                  <v-card-title v-text="card.title"></v-card-title>
                </v-img>
              </v-card>
            </router-link>
          </v-col>
        </v-row>

      </v-container>

      <Footer />

    </div>
  </div>

</template>

<script>
  import HeaderMain from "@/components/Header";
  import Footer from "@/components/Footer";
  import { getProjects } from "@/services";
  export default {
    components: {
      HeaderMain,
      Footer
    },
    data() {
      return {
        categories: [],
      };
    },
    mounted() {
       this.fetchData();
    },
    methods: {
      fetchData() {
        this.categories = getProjects().map(item => {
          return {
            title: item.title,
            slug: item.slug,
            imgSrc: require(`@/assets/carolyn/${item.slug}/${item.photo}`)
          }
        })
      },
    },
  };
</script>

<style lang="scss" scoped>
  .box-layout {
    .-rn-footer {
      background: #f9f9f9;
    }
    header.header.header-flat {
      padding: 0;
    }
    #home-cards {
      .v-card {
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.25);
        transition: box-shadow 500ms;
        &:hover {
          box-shadow: 0 3px 30px rgb(0 0 0 / 0.5);
        }
      }
      .v-card__title {
        word-break: break-word;
        background-color: rgb(0 0 0 / 0.5);
      }
    }
  }
</style>